import React from "react";
import styled, { css } from "styled-components";

import bc from "../../images/mb-bc.png";
import oml from "../../images/mb-oml.png";
// import sawr from "../../images/mb-sawr.png";
// import csfea from "../../images/mb-csfea.png";
import remoteup from "../../images/mb-remoteup.png";
import alf from "../../images/mb-parsdc.png";

import PropTypes from "prop-types";

const FeaturedWorkContainerDiv = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 60px auto 60px;

  ${(props) =>
    props.darkmode &&
    css`
      color: white;
    `}

  p {
    display: flex;
  }

  div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media (min-width: 1200px) {
      justify-content: space-between;
    }

    div {
      width: 100%;
      margin: 40px 0 0;

      @media (min-width: 500px) {
        max-width: 800px;
      }

      @media (min-width: 1200px) {
        max-width: 500px;
      }

      div {
        padding: 20px 0;
        width: 100%;
        opacity: 1;
        transition: 0.2s;
        display: flex;
        align-items: center;
        flex-direction: column;

        &:hover {
          opacity: 0.8;
          padding: 16px 0 24px;
        }

        h4 {
          width: 100%;
          line-height: 2;
          text-align: center;
          display: block;
          letter-spacing: 4px;
          margin-top: 20px;
        }
      }
    }
  }
`;

const FeaturedWorkContainer = (props) => {
  return (
    <FeaturedWorkContainerDiv darkmode={props.darkmode}>
      <p id="featured-work">Featured Projects</p>
      <div>
        {/* Remote-Job Board(React) */}
        <div>
          <div>
            <a href="https://github.com/pesto-students/f1-remoteup-beta-fe" target="_blank" rel="noopener noreferrer">
              <img
                data-testid="remoteup"
                src={remoteup}
                alt="RemoteUp Landing Page"
              />
            </a>
            <h4>Remote-Job board</h4>
          </div>
        </div>

        {/* Machine Learning */}
        <div>
          <div>
            <a href="https://github.com/minithb/Operationalizing-Machine-Learning-using-Azure" target="_blank" rel="noopener noreferrer">
              <img
                data-testid="omlPicture"
                src={oml}
                alt="Operationalizing machine learning sample"
              />
            </a>
            <h4>Operationalizing Machine Learning</h4>
          </div>
        </div>

        {/* Enhanced Alpha */}
        {/* <div>
          <div>
            <a href="https://github.com/minithb/Combining-Signals-for-Enhanced-Alpha" target="_blank" rel="noopener noreferrer">
              <img data-testid="chartPicture" src={csfea} alt="chart sample" />
            </a>
            <h4>Combining Signals for Enhanced Alpha</h4>
          </div>
        </div> */}

        {/* Sentiment Analysis */}
        {/* <div>
          <div>
            <a href="https://github.com/minithb/Sentinment-Analysis-with-RNN" target="_blank" rel="noopener noreferrer">
              <img
                data-testid="sentimentPicture"
                src={sawr}
                alt="Sentiment analysis sample"
              />
            </a>
            <h4>Sentiment Analysis with RNN</h4>
          </div>
        </div> */}

        {/* Advanced Lane Finding */}
        <div>
          <div>
            <a href="https://github.com/minithb/CarND-Advanced-Lane-Lines" target="_blank" rel="noopener noreferrer">
              <img
                data-testid="advancedlanelines"
                src={alf}
                alt="Advanced Lane Lines"
              />
            </a>
            <h4>Advanced Lane Finding</h4>
          </div>
        </div>

        {/* Behavioral Cloning */}
        <div>
          <div>
            <a href="https://github.com/minithb/CarND-Behavioral-Cloning" target="_blank" rel="noopener noreferrer">
              <img
                data-testid="steeringWheel"
                src={bc}
                alt="Steering Wheel sample"
              />
            </a>
            <h4>Behavioural Cloning</h4>
          </div>
        </div>
      </div>
    </FeaturedWorkContainerDiv>
  );
};

FeaturedWorkContainer.propTypes = {
  darkmode: PropTypes.bool.isRequired,
};

export default FeaturedWorkContainer;
