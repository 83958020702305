import React from "react";
import styled, { keyframes, css } from "styled-components";

import remedoLogo from "../../images/remedoLogo.png";
import remedoLogoWhite from "../../images/remedoLogoWhite.png";
import shellLogo from "../../images/shellLogo.png";
import shellLogoWhite from "../../images/shellLogoWhite.png";
import udacityLogo from "../../images/udacityLogo.png";
import udacityLogoWhite from "../../images/udacityLogoWhite.png"

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const hoverLink = keyframes`
  0% {
    color: #5A22DB;
  }
  100% {
    color: #AD91ED;
  }
`;

const hoverLinkDarkmode = keyframes`
  0% {
    color: #1a97f0;
  }
  100% {
    color: #6DBCF5;
  }
`;

const HeaderContainerDiv = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 50px 0;
  margin: 0 auto;

  ${(props) =>
    props.darkmode &&
    css`
      color: white;
    `}

  p {
    max-width: 1000px;
    width: 90%;

    a {
      font-size: 3rem;
      color: #6936de;

      ${(props) =>
        props.darkmode &&
        css`
          color: #1a97f0;
        `}

      &:hover {
        ${(props) =>
          props.darkmode
            ? css`
                animation: ${hoverLinkDarkmode} 0.5s forwards;
              `
            : css`
                animation: ${hoverLink} 0.5s forwards;
              `}
      }

      &:active {
        ${(props) =>
          props.darkmode
            ? css`
                color: #b6465f;
              `
            : css`
                color: #ad91ed;
              `}
      }
    }
  }
`;

const HeaderContainer = (props) => {
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setInnerWidth(window.innerWidth);
    });
  }, [window.innerWidth]);
  const CurrentPreviousWork = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 800px) {
    flex-direction: row;
  }

  div {
    h4 {
      letter-spacing: 4px;
      margin-top: 50px;

      @media (min-width: 800px) {
        margin-top: 100px;
      }
    }

    div {
      padding: 10px 0;
      width: 100%;
      max-width: 380px;
      opacity: 1;
      transition: 0.2s;
      margin: 0;

      @media (min-width: 800px) {
        width: 380px;
      }

      a:hover {
        opacity: 0.8;
      }
    }
  }

  div:first-child {
    width: 100%;

    a > img {
      width: 108px;
    }

    @media (min-width: 500px) {
      padding-right: 5%;
    }

    @media (min-width: 800px) {
      max-width: 700px;
      padding-right: 6.5%;
    }
    .remedo {
      padding-top: 10px;
    }
    .remedo-img {
      padding: 5px;
    }
    .shell {
      margin-left: ${props => props.width < 500 ? "2px" : "12px"};
    }
    .udacity-img {
      margin-left: 0px;
      padding: 10px;
    }
    .udacity {
      margin-left: ${props => props.width < 500 ? "0px" : "12px"};
    }
  }
`;
  return (
    <HeaderContainerDiv darkmode={props.darkmode}>
      <p>
        I'm Minith Jain, a{" "}
        <a href="https://github.com/minithb" target="_blank" rel="noopener noreferrer">software engineer</a> living in{" "}
        <a href="https://www.google.com/search?q=thane" target="_blank" rel="noopener noreferrer">Thane</a> and currently
        founding engineer at{" "} <a href="https://energos.ai" target="_blank" rel="noopener noreferrer">energos.ai</a>. {" "}
        {/* a student at <a href="https://www.pesto.tech/" target="_blank" rel="noopener noreferrer">Pesto</a>.  */}
        Let's{" "}
        <Link to="/contact">talk</Link> or{" "}
        <a href="https://www.calendly.com/minith/30min" target="_blank" rel="noopener noreferrer">meet</a>.
      </p>
      <CurrentPreviousWork width={innerWidth}>
        <div>
          <h4>Previously:</h4>
          <div>
            <a className="remedo" href="https://www.remedoapp.com/" target="_blank" rel="noopener noreferrer">
              <img
                className="remedo-img"
                data-testid="remedoLogo"
                src={props.darkmode ? remedoLogoWhite : remedoLogo}
                alt="Current remedo logo"
              />
            </a>
            <a className="shell" href="https://www.shell.ai/" target="_blank" rel="noopener noreferrer">
              <img
                className="shell-img"
                data-testid="shellLogo"
                src={props.darkmode ? shellLogoWhite : shellLogo}
                alt="Current shell logo"
              />
            </a>
            <a className="udacity" href="https://www.udacity.com/" target="_blank" rel="noopener noreferrer">
              <img
                className="udacity-img"
                data-testid="udacityLogo"
                src={props.darkmode ? udacityLogoWhite : udacityLogo}
                alt="Current udacity logo"
              />
            </a>
          </div>
        </div>
      </CurrentPreviousWork>
    </HeaderContainerDiv>
  );
};

HeaderContainer.propTypes = {
  darkmode: PropTypes.bool.isRequired,
};

export default HeaderContainer;
