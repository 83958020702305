import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import PropTypes from "prop-types";
import GitHub from "../../images/logo-github.svg";
import LinkedIn from "../../images/logo-linkedin.svg";
import Twitter from "../../images/logo-twitter.svg";
import Instagram from "../../images/logo-instagram.svg";
import InstagramImage from "../../images/instagram.png";
import Mail from "../../images/mail-outline.svg";

const hoverLink = keyframes`
  0% {
    color: black;
  }
  100% {
    color: #AD91ED;
  }
`;

const hoverLinkDarkmode = keyframes`
  0% {
    color: white;
  }
  100% {
    color: #6DBCF5;
  }
`;

const contactHover = keyframes`
  0% {
    color: #5A22DB;
  }
  100% {
    color: #AD91ED;
  }
`;

const contactHoverDarkmode = keyframes`
  0% {
    color: #1a97f0;
  }
  100% {
    color: #6DBCF5;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 70px auto 0;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.darkmode &&
    css`
      color: white;
    `}

  div:first-child {
    @media (min-width: 800px) {
      display: flex;
    }

    p {
      margin-top: 20px;
    }

    a {
      color: #6936de;
      display: block;
      margin: 30px 0 10px;
      font-size: 3rem;
      line-height: 1.8;

      @media (min-width: 800px) {
        margin: 20px 10px 0;
      }

      ${(props) =>
        props.darkmode &&
        css`
          color: #1a97f0;
        `}

      &:hover {
        ${(props) =>
          props.darkmode
            ? css`
                animation: ${contactHoverDarkmode} 0.5s forwards;
              `
            : css`
                animation: ${contactHover} 0.5s forwards;
              `}
      }

      &:active {
        ${(props) =>
          props.darkmode
            ? css`
                color: #b6465f;
              `
            : css`
                color: #ad91ed;
              `}
      }
    }
  }
`;

const DesktopLinks = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
    display: flex;
    flex-direction: row;
  }

  a {
    margin-top: 30px;
    font-size: 1.6rem;

    @media (min-width: 800px) {
      margin: 50px 3% 120px 0;
    }

    ${(props) =>
      props.darkmode &&
      css`
        color: white;
      `}

    &:hover {
      ${(props) =>
        props.darkmode
          ? css`
              animation: ${hoverLinkDarkmode} 0.2s forwards;
            `
          : css`
              animation: ${hoverLink} 0.2s forwards;
            `}
    }
  }
  img {
    height: 24px;
    width: 24px;
    vertical-align: middle;
    // filter: invert(29%) sepia(15%) saturate(700%) hue-rotate(174deg)
    //   brightness(91%) contrast(97%);
  }
  span {
    vertical-align: middle;
  }
  .white {
    filter: invert(100%) sepia(0%) saturate(7495%) hue-rotate(262deg)
      brightness(93%) contrast(112%);
  }
  .linkedin {
    filter: invert(21%) sepia(88%) saturate(3272%) hue-rotate(184deg)
      brightness(94%) contrast(89%);
  }
  .twitter {
    filter: invert(54%) sepia(88%) saturate(2485%) hue-rotate(161deg)
      brightness(96%) contrast(102%);
  }
  .github {
    filter: invert(0%) sepia(35%) saturate(3333%) hue-rotate(53deg)
      brightness(92%) contrast(84%);
  }

  a:last-child {
    margin-bottom: 50px;
  }
`;

const Copyright = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  h4 {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 80px;
    color: black;

    @media (min-width: 500px) {
      font-size: 1.6rem;
    }

    ${(props) =>
      props.darkmode &&
      css`
        color: white;
      `}
  }
`;

const Footer = (props) => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <FooterContainer darkmode={props.darkmode}>
        <div>
          <p>Looking to start a project?</p>
          <Link to="/contact">Get in contact.</Link>
        </div>
        <DesktopLinks darkmode={props.darkmode}>
          <a href="https://github.com/minithb" target="_blank" rel="noopener noreferrer">
            <img
              src={GitHub}
              className={props.darkmode ? "white" : "github"}
              alt="github logo"
            />{" "}
            <span>Github</span>
          </a>
          <a href="https://www.linkedin.com/in/minith/" target="_blank" rel="noopener noreferrer">
            <img
              src={LinkedIn}
              className={props.darkmode ? "white" : "linkedin"}
              alt="LinkedIn logo"
            />{" "}
            <span>LinkedIn</span>
          </a>
          <a href="https://twitter.com/minithb" target="_blank" rel="noopener noreferrer">
            <img
              src={Twitter}
              className={props.darkmode ? "white" : "twitter"}
              alt="Twitter logo"
            />{" "}
            <span>Twitter</span>
          </a>
          <a href="https://www.instagram.com/minsbj/" target="_blank" rel="noopener noreferrer">
            <img
              src={props.darkmode ? Instagram : InstagramImage}
              className={props.darkmode ? "white" : ""}
              alt="Instagram logo"
            />{" "}
            <span>Instagram</span>
          </a>
          <Link to="/contact">
            <img
              src={Mail}
              className={props.darkmode ? "white" : "github"}
              alt="Mail logo"
            />{" "}
            <span>Email</span>
          </Link>
        </DesktopLinks>
      </FooterContainer>
      <Copyright darkmode={props.darkmode}>
        <h4>{`Copyright © ${currentYear} Minith Jain`}</h4>
      </Copyright>
    </>
  );
};

Footer.propTypes = {
  darkmode: PropTypes.bool.isRequired,
};

export default Footer;
